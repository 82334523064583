import { useLocation } from 'react-router';
import React, { useContext } from 'react';
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon,  } from 'react-share';
import { styled } from '@mui/material/styles';
import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemText, Modal, Typography } from '@mui/material';
import { ShareContext } from '../../utils/share-context';

const MainBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '80vh',
    overflow: 'auto',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,
    borderRadius: '20px',
    [theme.breakpoints.down('lg')]: {
        width: '75%'
    },
    [theme.breakpoints.down('md')]: {
        width: '85%'
    }
}));

const ShareModal = () => {
    const { open, setOpen } = useContext(ShareContext);
    const { pathname } = useLocation();
    const shareLink = `https://inlovingmemory.co.ke${pathname}`;
    return (
        <Modal open={open} onClose={() => { setOpen(false) }}>
            <MainBox>
                <Typography sx={{ margin: 2 }} variant='h6'>Share To</Typography>
                <Divider />
                <List sx={{ alignSelf: 'flex-start', display: 'flex', flexDirection: 'column' }}>
                    <FacebookShareButton url={shareLink}>
                        <ListItem>
                            <ListItemAvatar><Avatar><FacebookIcon size={40} /></Avatar></ListItemAvatar>
                            <ListItemText primary='Facebook' />
                        </ListItem>
                    </FacebookShareButton>
                    <WhatsappShareButton url={shareLink}>
                        <ListItem>
                            <ListItemAvatar><Avatar><WhatsappIcon size={40} /></Avatar></ListItemAvatar>
                            <ListItemText primary='WhatsApp' />
                        </ListItem>
                    </WhatsappShareButton>
                    <TwitterShareButton url={shareLink}>
                        <ListItem>
                            <ListItemAvatar><Avatar><TwitterIcon size={40} /></Avatar></ListItemAvatar>
                            <ListItemText primary='Twitter' />
                        </ListItem>
                    </TwitterShareButton>
                    <EmailShareButton url={shareLink}>
                        <ListItem>
                            <ListItemAvatar><Avatar><EmailIcon size={40} /></Avatar></ListItemAvatar>
                            <ListItemText primary='E-Mail' />
                        </ListItem>
                    </EmailShareButton>
                </List>
            </MainBox>
        </Modal>
    )
};

export default ShareModal
