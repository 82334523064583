import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------
export const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
export const HowToWritePage = Loadable(lazy(() => import('../pages/HowToWritePage')));
export const TermsAndConditionsPage = Loadable(lazy(() => import('../pages/TermsAndConditionsPage')));
export const PrivacyPolicyPage = Loadable(lazy(() => import('../pages/PrivacyPolicyPage')));
export const SingleMemorialPage = Loadable(lazy(() => import('../pages/SingleMemorialPage')));
export const SingleBlogPage = Loadable(lazy(() => import('../pages/SingleBlogPage')));
export const NewMemorialPage = Loadable(lazy(() => import('../pages/NewMemorialPage')));
export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
export const SingupPage = Loadable(lazy(() => import('../pages/SingupPage')));
export const ForgotPasswordPage = Loadable(lazy(() => import('../pages/ForgotPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/ResetPasswordPage')));
export const PlanPage = Loadable(lazy(() => import('../pages/PlanPage')));

export const DashboardPage = Loadable(lazy(() => import('../pages/DashboardPage')));
export const AccountPage = Loadable(lazy(() => import('../pages/AccountPage')));
export const DashboardMemorialPage = Loadable(lazy(() => import('../pages/DashboardMemorialPage')));
export const AdminUsersPage = Loadable(lazy(() => import('../pages/AdminUsersPage')));
export const AdminMemorialsPage = Loadable(lazy(() => import('../pages/AdminMemorialsPage')));
export const AdminPlansPage = Loadable(lazy(() => import('../pages/AdminPlansPage')));
export const AdminAddPlanPage = Loadable(lazy(() => import('../pages/AdminAddPlanPage')));
export const AdminTherapistsPage = Loadable(lazy(() => import('../pages/AdminTherapistsPage')));
export const AdminAddTherapistPage = Loadable(lazy(() => import('../pages/AdminAddTherapistPage')));
export const AdminBlogsPage = Loadable(lazy(() => import('../pages/AdminBlogsPage')));
export const AdminAddBlogPage = Loadable(lazy(() => import('../pages/AdminAddBlogPage')));
export const AdminProductsPage = Loadable(lazy(() => import('../pages/AdminProductsPage')));
export const AdminAddProductPage = Loadable(lazy(() => import('../pages/AdminAddProductPage')));
export const AdminRequestsPage = Loadable(lazy(() => import('../pages/AdminRequestsPage')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
