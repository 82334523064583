import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
// import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  DashboardPage,
  LoginPage,
  LandingPage,
  SingleMemorialPage,
  PlanPage,
  SingupPage,
  NewMemorialPage,
  DashboardMemorialPage,
  AccountPage,
  AdminUsersPage,
  AdminMemorialsPage,
  AdminPlansPage,
  AdminAddPlanPage,
  AdminTherapistsPage,
  AdminAddTherapistPage,
  AdminBlogsPage,
  AdminAddBlogPage,
  SingleBlogPage,
  AdminProductsPage,
  AdminAddProductPage,
  AdminRequestsPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  AboutPage,
  HowToWritePage,
  TermsAndConditionsPage,
  PrivacyPolicyPage,
} from './elements';
import RoleBasedGuard from '../auth/RoleBasedGuard';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <LandingPage />, index: true },
        { path: 'about', element: <AboutPage /> },
        { path: 'how-to-write-an-obituary', element: <HowToWritePage /> },
        { path: 'terms-and-conditions', element: <TermsAndConditionsPage /> },
        { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <SingupPage />
            </GuestGuard>
          ),
        },
        {
          path: 'forgot-password',
          element: (
            <GuestGuard>
              <ForgotPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password/:token',
          element: (
            <GuestGuard>
              <ResetPasswordPage />
            </GuestGuard>
          ),
        },
        { path: 'memorial/:url', element: <SingleMemorialPage /> },
        { path: 'blog/:id', element: <SingleBlogPage /> },
        {
          path: 'plan',
          element: (
            <AuthGuard>
              <PlanPage />
            </AuthGuard>
          )
        },
        {
          path: 'new',
          element: (
            <AuthGuard>
              <Navigate to="/dashboard/new" replace />
            </AuthGuard>
          )
        }
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/home" replace />, index: true },
        { path: 'home',element: <DashboardPage /> , index: true },
        { path: 'new',element: <NewMemorialPage />, index: true },
        { path: 'memorial/:url', element: <DashboardMemorialPage /> },
        { path: 'account', element: <AccountPage /> },
        {
          path: 'admin',
          children: [
            { element: <Navigate to="/dashboard/admin/users" replace />, index: true },
            { path: 'users', element: <RoleBasedGuard roles hasContent><AdminUsersPage /></RoleBasedGuard> },
            { path: 'memorials', element: <RoleBasedGuard roles hasContent><AdminMemorialsPage /></RoleBasedGuard> },
            { path: 'plans', element: <RoleBasedGuard roles hasContent><AdminPlansPage /></RoleBasedGuard> },
            { path: 'add-plan', element: <RoleBasedGuard roles hasContent><AdminAddPlanPage /></RoleBasedGuard> },
            { path: 'therapists', element: <RoleBasedGuard roles hasContent><AdminTherapistsPage /></RoleBasedGuard> },
            { path: 'add-therapist', element: <RoleBasedGuard roles hasContent><AdminAddTherapistPage /></RoleBasedGuard> },
            { path: 'blogs', element: <RoleBasedGuard roles hasContent><AdminBlogsPage /></RoleBasedGuard> },
            { path: 'add-blog', element: <RoleBasedGuard roles hasContent><AdminAddBlogPage /></RoleBasedGuard> },
            { path: 'products', element: <RoleBasedGuard roles hasContent><AdminProductsPage /></RoleBasedGuard> },
            { path: 'add-product', element: <RoleBasedGuard roles hasContent><AdminAddProductPage /></RoleBasedGuard> },
            { path: 'pending-requests', element: <RoleBasedGuard roles hasContent><AdminRequestsPage /></RoleBasedGuard> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
