import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// export const SERVER_URL = 'http://localhost:8000';
export const SERVER_URL = 'https://in-loving-memory-s2il.onrender.com';
// export const SERVER_URL = 'https://in-loving-memory.onrender.com';
// export const SERVER_URL = 'https://in-loving-memory-ke-052cbbcdfc2a.herokuapp.com';
