// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'dashboard',
    items: [
      { title: 'Memorials', path: PATH_DASHBOARD.home, icon: ICONS.dashboard },
      { title: 'Account', path: PATH_DASHBOARD.account, icon: ICONS.user },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'admin',
        roles: true,
        path: PATH_DASHBOARD.admin.root,
        icon: ICONS.user,
        children: [
          { title: 'Users', path: PATH_DASHBOARD.admin.users, roles: true, },
          { title: 'Memorials', path: PATH_DASHBOARD.admin.memorials, roles: true, },
          { title: 'Plans', path: PATH_DASHBOARD.admin.plans, roles: true, },
          { title: 'Therapists', path: PATH_DASHBOARD.admin.therapists, roles: true, },
          { title: 'Blogs', path: PATH_DASHBOARD.admin.blogs, roles: true, },
          { title: 'Products', path: PATH_DASHBOARD.admin.producst, roles: true, },
          { title: 'Pending Requests', path: PATH_DASHBOARD.admin.pendingRequests, roles: true, },
        ],
      },
    ],
  },
];

export default navConfig;
